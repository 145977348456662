// src/pages/FaqPage.tsx
import React from 'react';
import Accordion from '../components/Accordion';

const faqs = [
  {
    question: "How does this service work?",
    answer: "You submit your resume and job description, and our AI analyzes the alignment between them."
  },
  {
    question: "Is this service free?",
    answer: "Yes, it's completely free to use."
  },
  // Add more FAQs here
];

const FaqPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-center mb-6">Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <Accordion key={index} title={faq.question} content={faq.answer} />
      ))}
    </div>
  );
};

export default FaqPage;
