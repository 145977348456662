import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';
import axios from 'axios';

const SubmitPage = () => {
  const [mode, setMode] = useState('paste');
  const [jobDescriptionText, setJobDescriptionText] = useState('');
  const [resumeText, setResumeText] = useState('');
  const [jobDescriptionFile, setJobDescriptionFile] = useState<File | null>(null);
  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  async function uploadFiles(resume: File, jobDescription: File) {
    const formData = new FormData();
    formData.append('cv', resume);
    formData.append('jd', jobDescription);

    try {
        const response = await axios.post('https//resumai.fredoms.com/AiChecker/ResumeCheckFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error uploading files:', error);
        return null;
    }
}

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (name === 'jobDescriptionText') {
      setJobDescriptionText(value);
    } else {
      setResumeText(value);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (name === 'jobDescriptionFile') {
      setJobDescriptionFile(files ? files[0] : null);
    } else {
      setResumeFile(files ? files[0] : null);
    }
  };

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     setShowModal(true); // Show modal on submit
//     if (mode === 'paste' && (!jobDescriptionText || !resumeText)) {
//         alert('Please paste both your job description and resume text.');
//         return;
//     } else if (mode === 'upload' && (!jobDescriptionFile || !resumeFile)) {
//         alert('Please upload both your job description and resume files.');
//         return;
//     }

//     try {
//         // Upload files
//         console.log('Uploading files...');
//         const response = await uploadFiles(resumeFile!, jobDescriptionFile!);
//         console.log('Files uploaded successfully.');
//         console.log('API response:', response); // Log the API response

//         // Simulate a file upload or processing delay
//         setTimeout(() => {
//             setShowModal(false); // Hide modal after processing
//             navigate('/results', { state: { apiData: response } }); // Pass the API response data to ResultsPage
//         }, 5000);
//     } catch (error) {
//         console.error('Error uploading files:', error);
//         setShowModal(false); // Hide modal on error
//     }
// };

const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
  setShowModal(true); // Show modal on submit
  if (mode === 'paste' && (!jobDescriptionText || !resumeText)) {
    alert('Please paste both your job description and resume text.');
    return;
  } else if (mode === 'upload' && (!jobDescriptionFile || !resumeFile)) {
    alert('Please upload both your job description and resume files.');
    return;
  }

  try {
    if (mode === 'paste') {
      // Send POST request with text data
      console.log('Sending request...');
      const response = await axios.post('https://resumai.fredoms.com/AiChecker/ResumeChecktext', {
        curriculumVitae: resumeText,
        jobDescription: jobDescriptionText
      });
      console.log('Request sent successfully.');
      console.log('API response:', response.data); // Log the API response

      // Simulate a processing delay
      setTimeout(() => {
        setShowModal(false); // Hide modal after processing
        navigate('/results', { state: { apiData: response.data } }); // Pass the API response data to ResultsPage
      }, 5000);
    } else if (mode === 'upload') {
      // Upload files
      console.log('Uploading files...');
      const response = await uploadFiles(resumeFile!, jobDescriptionFile!);
      console.log('Files uploaded successfully.');
      console.log('API response:', response); // Log the API response

      // Simulate a file upload or processing delay
      setTimeout(() => {
        setShowModal(false); // Hide modal after processing
        navigate('/results', { state: { apiData: response } }); // Pass the API response data to ResultsPage
      }, 5000);
    }
  } catch (error) {
    console.error('Error:', error);
    setShowModal(false); // Hide modal on error
  }
};

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-center mb-6">Submit Your Job Description and Resume</h1>
      <div className="flex justify-center gap-4 mb-4">
        <button onClick={() => setMode('paste')} className={`py-2 px-4 ${mode === 'paste' ? 'bg-blue-700' : 'bg-gray-300'}`}>Paste Mode</button>
        <button onClick={() => setMode('upload')} className={`py-2 px-4 ${mode === 'upload' ? 'bg-blue-700' : 'bg-gray-300'}`}>Upload Mode</button>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        {mode === 'paste' ? (
          <>
            <div>
              <label htmlFor="jobDescriptionText" className="block text-sm font-medium text-gray-700">Paste Job Description:</label>
              <textarea
                id="jobDescriptionText"
                name="jobDescriptionText"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                rows={4}
                value={jobDescriptionText}
                onChange={handleTextChange}
                required
              />
            </div>
            <div>
              <label htmlFor="resumeText" className="block text-sm font-medium text-gray-700">Paste Your Resume:</label>
              <textarea
                id="resumeText"
                name="resumeText"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                rows={4}
                value={resumeText}
                onChange={handleTextChange}
                required
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <label htmlFor="jobDescriptionFile" className="block text-sm font-medium text-gray-700">Upload Job Description (PDF only):</label>
              <input
                id="jobDescriptionFile"
                type="file"
                name="jobDescriptionFile"
                accept=".pdf"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                onChange={handleFileChange}
                required
              />
            </div>
            <div>
              <label htmlFor="resumeFile" className="block text-sm font-medium text-gray-700">Upload Your Resume (PDF only):</label>
              <input
                id="resumeFile"
                type="file"
                name="resumeFile"
                accept=".pdf"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                onChange={handleFileChange}
                required
              />
            </div>
          </>
        )}
        <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Submit
        </button>
      </form>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <p className="text-lg font-semibold">Submission received. We are now processing your data.</p>
      </Modal>
    </div>
  );
};

export default SubmitPage;
