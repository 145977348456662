import React from 'react';

const BlogPage: React.FC = () => {
  // Dummy data for the sake of example
  const blogPosts = [
    { title: "5 Tips to Enhance Your Resume", content: "Here are five essential tips to make your resume stand out." },
    { title: "How to Tailor Your Resume for Any Job Description", content: "Learn how to modify your resume to better align with specific job descriptions." },
    // More posts can be added here
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-center mb-6">Blog</h1>
      {blogPosts.map((post, index) => (
        <div key={index} className="bg-white p-6 shadow-md rounded-lg mb-4">
          <h2 className="text-xl font-bold">{post.title}</h2>
          <p className="text-md mt-2">{post.content}</p>
        </div>
      ))}
    </div>
  );
};

export default BlogPage;
