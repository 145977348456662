import React, { useState, FunctionComponent } from 'react';

interface AccordionProps {
  title: string;
  content: string;
}

const Accordion: FunctionComponent<AccordionProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b-2">
      <button
        className="py-2 px-4 w-full text-left font-semibold text-lg"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
      </button>
      {isOpen && (
        <div className="p-4">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
