import { Doughnut, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale,
} from "chart.js";

import { useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale
);
// interface Section {
//   title: string;
//   content: string[];
// }

const ResultsPage = () => {
  const location = useLocation();
  const apiData = location.state?.apiData;
  const jdKeywords: { [keyword: string]: number } = {};
const cvKeywords: { [keyword: string]: number } = {};

const imageUrls = {
    "cvFileURl": apiData.cvFileURl.replace("localhost:4220", "resumai.fredoms.com"),
    "jdFileURl" : apiData.jdFileURl.replace("localhost:4220", "resumai.fredoms.com")
} 

console.log(imageUrls);

const [cvImage, setCvImage] = useState<string | undefined>(undefined);
const [jdImage, setJdImage] = useState<string | undefined>(undefined);


useEffect(() => {
    axios.post("https://" + imageUrls.cvFileURl, {}, { responseType: 'blob' })
    .then(response => {
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = () => {
        if (reader.result !== null) {
          setCvImage(reader.result as string);
        }
      };
    })
    .catch(error => {
      console.error("Error fetching CV image:", error);
    });
  
  axios.post("https://" + imageUrls.jdFileURl, {}, { responseType: 'blob' })
    .then(response => {
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = () => {
        if (reader.result !== null) {
          setJdImage(reader.result as string);
        }
      };
    })
    .catch(error => {
      console.error("Error fetching JD image:", error);
    });
  
  }, []);

for (const keyword in apiData.frequencyJD) {
  jdKeywords[keyword] = apiData.frequencyJD[keyword];
}

for (const keyword in apiData.frequencyCV) {
  cvKeywords[keyword] = apiData.frequencyCV[keyword];
}

const jdKeywordFrequency = Object.values(jdKeywords).reduce((a, b) => a + b, 0);
const cvKeywordFrequency = Object.values(cvKeywords).reduce((a, b) => a + b, 0);
const score = (cvKeywordFrequency / jdKeywordFrequency) * 100;

console.log("score" + score);

  const [keyWordsFrequencyData, setKeyWordsFrequencyData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
      borderColor: string[];
      borderWidth: number;
    }[];
  }>({
    labels: [],
    datasets: [{
      label: "",
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    }],
  });
  
  const [matchScoreData, setMatchScoreData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
      borderColor: string[];
      borderWidth: number;
    }[];
  }>({
    labels: [],
    datasets: [{
      label: "",
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    }],
  });

  const [scoreData, setScoreData] = useState({
    labels: ['Score'],
    datasets: [{
      label: 'Score',
      data: [score],
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1
    }]
  });

  
  
  useEffect(() => {
    if (apiData) {
      // Calculate key word frequency in JD and CV
      const keyWordsFrequency = {
        labels: ["JD", "CV"],
        datasets: [
          {
            label: "Key Word Frequency",
            data: [jdKeywordFrequency, cvKeywordFrequency],
            backgroundColor: [
              "rgba(75, 192, 192, 0.2)",
              "rgba(255, 99, 132, 0.2)",
            ],
            borderColor: [
              "rgba(75, 192, 192, 1)",
              "rgba(255, 99, 132, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };


      // Calculate match score based on JD and CV
      const matchScore = {
        labels: ["Match", "Mismatch"],
        datasets: [
          {
            label: "ATS Score",
            data: [
              (cvKeywordFrequency / jdKeywordFrequency) * 100,
              100 - (cvKeywordFrequency / jdKeywordFrequency) * 100,
            ],
            backgroundColor: [
              "rgba(75, 192, 192, 0.2)",
              "rgba(255, 99, 132, 0.2)",
            ],
            borderColor: [
              "rgba(75, 192, 192, 1)",
              "rgba(255, 99, 132, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };

      

      setKeyWordsFrequencyData(keyWordsFrequency);
      setMatchScoreData(matchScore);
    }

  }, [apiData]);

  const sections = apiData.review
    .split("###")
    .map((section: string, index: number) => (
      <div key={index} className="mb-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <ReactMarkdown
            components={{
              h1: ({ children }) => (
                <h1 className="text-3xl font-bold my-4">{children}</h1>
              ),
              h2: ({ children }) => (
                <h2 className="text-2xl font-bold my-3">{children}</h2>
              ),
              h3: ({ children }) => (
                <h3 className="text-xl font-bold my-2">{children}</h3>
              ),
              p: ({ children }) => <p className="text-base my-2">{children}</p>,
              ul: ({ children }) => (
                <ul className="list-disc list-inside my-2">{children}</ul>
              ),
              li: ({ children }) => <li className="text-base">{children}</li>,
            }}
          >
            {section}
          </ReactMarkdown>
        </div>
      </div>
    ));

  if (!apiData) {
    return <div>Loading...</div>;
  }
  const atsData = {
    labels: ["Match", "Mismatch"],
    datasets: [
      {
        label: "ATS Score",
        data: [apiData.frequencyCV, apiData.frequencyJD],
        backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };
  console.log(atsData)

 

  

  return (
    <div className="container mx-auto px-4 py-8">
      <div>
        <h1 className="text-2xl font-semibold leading-tight text-center">
          Your Resume Analysis
        </h1>
      </div>
      {sections}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {keyWordsFrequencyData && (
          <div className="p-4 border rounded shadow">
            <h2 className="font-semibold text-center">Key Word Frequency</h2>
            <Doughnut data={keyWordsFrequencyData} />
          </div>
        )}
        <div className="p-4 border rounded shadow">
    <h2 className="font-semibold text-center">CV File</h2>
    <img src={cvImage} alt="CV File" />
  </div>
  <div className="p-4 border rounded shadow">
  <h2 className="font-semibold text-center">JD File</h2>
  {jdImage ? <img src={jdImage} alt="JD File" /> : null}
</div>
      </div>
    </div>
  );
};

export default ResultsPage;
