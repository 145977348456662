import React from 'react';

const AboutPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-center mb-6">About Us</h1>
      <p className="text-lg">
        Resume Insight was founded with the mission to help job seekers improve their chances of landing their dream jobs by aligning their resumes with job descriptions.
      </p>
      {/* Additional paragraphs or images can be added here */}
    </div>
  );
};

export default AboutPage;
