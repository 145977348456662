import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NavBar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false); // State to handle mobile menu toggle

    return (
        <nav className="bg-gray-800 text-white p-4">
            <div className="container mx-auto flex justify-between items-center">
                <h1 className="font-bold text-lg">ResumeAI</h1>
                <div className="block lg:hidden">
                    <button onClick={() => setIsOpen(!isOpen)} className="text-white focus:outline-none">
                        {/* Icon for menu toggle */}
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            {isOpen ? (
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            ) : (
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            )}
                        </svg>
                    </button>
                </div>
                <div className={`lg:flex lg:items-center lg:w-auto w-full lg:h-auto ${isOpen ? "flex flex-col" : "hidden"}`}>
                    <Link to="/" className="block px-4 py-2 hover:text-gray-200">Home</Link>
                    <Link to="/about" className="block px-4 py-2 hover:text-gray-200">About</Link>
                    <Link to="/faq" className="block px-4 py-2 hover:text-gray-200">FAQ</Link>
                    <Link to="/blog" className="block px-4 py-2 hover:text-gray-200">Blog</Link>
                    <Link to="/submit" className="block px-4 py-2 hover:text-gray-200">Submit</Link>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
