import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const messages = [
    "Match your resume to your dream job using AI-driven insights.",
    "Discover the key to successful applications.",
    "Get personalized feedback instantly."
  ];
  const [messageIndex, setMessageIndex] = useState(0);
  const [displayMessage, setDisplayMessage] = useState(messages[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessageIndex(prevIndex => {
        const newIndex = (prevIndex + 1) % messages.length;
        setDisplayMessage(messages[newIndex]);  // Update the display message when index changes
        return newIndex;
      });
    }, 5000); // Change text every 5 seconds

    return () => clearInterval(intervalId);
  }, [messages]);

  return (
    <div className="flex flex-col items-center justify-center">
      {/* Hero Section */}
      <div className="bg-gray-800 text-white w-full p-12 text-center">
        <h1 className="text-4xl font-bold mb-2">Enhance Your Resume</h1>
        <div className="typing-container">
          <span className="typing-animation">{messages[messageIndex]}</span>
        </div>
        <div>
        <button onClick={() => navigate('/submit')} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Get Started
        </button>
        </div>
      </div>

      {/* Features Section */}
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold text-center">Why Choose ResumAI?</h2>
        <div className="flex flex-wrap justify-center mt-8">
          <FeatureCard
            title="Quick Analysis"
            description="Get instant feedback on how well your resume matches the job description."
          />
          <FeatureCard
            title="AI-Driven"
            description="Utilize advanced AI technology to identify key areas for improvement."
          />
          <FeatureCard
            title="Completely Free"
            description="No charges, free for all users, with unlimited resume submissions."
          />
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="bg-blue-600 text-white w-full p-12 text-center">
        <h2 className="text-3xl font-bold mb-4">Ready to Get Started?</h2>
        <p className="mb-4">Submit your resume today and take the first step towards your dream job.</p>
        <button
          onClick={() => navigate('/submit')}
          className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded"
        >
          Submit Now
        </button>
      </div>
    </div>
  );
};

export default HomePage;

// FeatureCard Component
interface FeatureCardProps {
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description }) => {
  return (
    <div className="m-4 p-4 bg-white rounded shadow-lg w-80 text-center">
      <h3 className="text-xl font-bold">{title}</h3>
      <p>{description}</p>
    </div>
  );
};
